import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCPdP0ch6vfoyaZtkc27UaZpv42GFvSlD8",
  authDomain: "xampus-prod.firebaseapp.com",
  projectId: "xampus-prod",
  storageBucket: "xampus-prod.appspot.com",
  messagingSenderId: "755773451546",
  appId: "1:755773451546:web:f2a2b36cf736c076d290a3",
  measurementId: "G-5LJ8Q945MR"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
